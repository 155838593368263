import * as SentrySvelte from '@sentry/svelte';
import { BrowserTracing } from '@sentry/browser';

SentrySvelte.init({
  dsn: 'https://d89c993329f24aafa634a206a41ab766@sentry.abstractly.io/11',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

SentrySvelte.setTag('svelteKit', 'browser');

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }) => {
  SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } });

  return {
    message: error.message
  };
});
